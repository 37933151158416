var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"indexBox"},[_c('div',{staticClass:"loginFromBox"},[_vm._m(0),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handReset}},[_c('a-form-item',_vm._b({attrs:{"has-feedback":""}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'password',
						{
							rules: [
								{
									required: true,
									message: '输入你的密码'
								}
							]
						}
					]),expression:"[\n\t\t\t\t\t\t'password',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: '输入你的密码'\n\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t]\n\t\t\t\t\t\t}\n\t\t\t\t\t]"}],staticStyle:{"width":"368px","height":"40px"},attrs:{"placeholder":"至少8位密码，区分大小写","type":"password"}})],1),_c('a-form-item',_vm._b({attrs:{"has-feedback":""}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'confirm',
						{
							rules: [
								{
									required: true,
									message: '确认你的密码'
								},
								{
									validator: _vm.compareToFirstPassword
								}
							]
						}
					]),expression:"[\n\t\t\t\t\t\t'confirm',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: '确认你的密码'\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\tvalidator: compareToFirstPassword\n\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t]\n\t\t\t\t\t\t}\n\t\t\t\t\t]"}],staticStyle:{"width":"368px","height":"40px"},attrs:{"placeholder":"至少8位密码，区分大小写","type":"password"},on:{"blur":_vm.handleConfirmBlur}})],1),_c('a-form-item',{staticClass:"centerBox"},[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v("确定重置")])],1)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticStyle:{"font-size":"14px","line-height":"1.5"}},[_c('a',[_vm._v("重置密码")])])}]

export { render, staticRenderFns }