<template>
	<div class="indexBox">
		<!-- //重置密码 -->
		<div class="loginFromBox">
			<p style="font-size: 14px; line-height: 1.5"><a>重置密码</a></p>
			<a-form id="components-form-demo-normal-login" :form="form" class="login-form" @submit="handReset">
				<a-form-item v-bind="formItemLayout" has-feedback>
					<a-input
						style="width: 368px; height: 40px"
						placeholder="至少8位密码，区分大小写"
						v-decorator="[
							'password',
							{
								rules: [
									{
										required: true,
										message: '输入你的密码'
									}
								]
							}
						]"
						type="password"
					/>
				</a-form-item>
				<a-form-item v-bind="formItemLayout" has-feedback>
					<a-input
						style="width: 368px; height: 40px"
						placeholder="至少8位密码，区分大小写"
						v-decorator="[
							'confirm',
							{
								rules: [
									{
										required: true,
										message: '确认你的密码'
									},
									{
										validator: compareToFirstPassword
									}
								]
							}
						]"
						type="password"
						@blur="handleConfirmBlur"
					/>
				</a-form-item>

				<a-form-item class="centerBox">
					<a-button type="primary" html-type="submit" class="login-form-button">确定重置</a-button>
				</a-form-item>
			</a-form>
		</div>
	</div>
</template>

<script>
// import Footer from '../../components/Footer'
import { message } from 'ant-design-vue'
import api from '../../api/api'
export default {
	// components: {
	// 	Footer
	// },
	beforeCreate() {
		this.form = this.$form.createForm(this, { name: 'normal_login' })
		this.form = this.$form.createForm(this, { name: 'register' })
	},
	data() {
		return {
			//   visible: false, //控制显示邮箱错误信息框
			showtable: '1', //控制展示首页登录、注册
			//外部数据
			des: '',
			confirmDirty: false,
			autoCompleteResult: [],
			formItemLayout: {
				labelCol: {
					xs: { span: 24 },
					sm: { span: 8 }
				},
				wrapperCol: {
					xs: { span: 24 },
					sm: { span: 16 }
				}
			},
			tailFormItemLayout: {
				wrapperCol: {
					xs: {
						span: 24,
						offset: 0
					},
					sm: {
						span: 16,
						offset: 8
					}
				}
			}
		}
	},

	methods: {
		//重置
		handReset(e) {
			e.preventDefault()
			this.form.validateFieldsAndScroll(async (err, values) => {
				const datalength = values.password
				console.log(`password lenth ${datalength.length}`)
				if (!err) {
					if (values.password.length > 6) {
						const data = await api.resetPassword({ ...values, token: this.$route.query.token })
						if (data.msg) {
							message.error(`${data.msg}`)
						} else {
							message.success('重置成功')
							sessionStorage.token = []
							setTimeout(() => {
								this.$router.push('/user')
							}, 1000)
						}
						//跳转登录
					} else {
						message.error('密码必须大于6位数')
					}
				}
			})
			this.showtable = '1'
		},
		handleConfirmBlur(e) {
			const value = e.target.value
			this.confirmDirty = this.confirmDirty || !!value
		},
		compareToFirstPassword(rule, value, callback) {
			const form = this.form
			if (value && value !== form.getFieldValue('password')) {
				callback('两次输入的密码不一致')
			} else {
				callback()
			}
		},
		validateToNextPassword(rule, value, callback) {
			const form = this.form
			if (value && this.confirmDirty) {
				form.validateFields(['confirm'], { force: true })
			}
			callback()
		}
	}
}
</script>

<style scoped lang="scss">
.indexBox {
	width: 1200px;
	height: 500px;
	margin: auto;
	// border: 1px solid rgba(121, 121, 121, 1);
	box-sizing: border-box;
	//登录样式
	.loginBox {
		width: 100%;
		height: 100%;
		background: red($color: #000000);
		h1 {
			margin: auto;
			margin-top: 100px;
			text-align: center;
			font-weight: bold;
		}
		.loginFromBox {
			a {
				color: #1890ff;
				font-weight: 500;
				padding: 12px 16px;
				display: inline-block;
				border-bottom: #1890ff 2px solid;
			}
			.boxleft {
				width: 100%;
				height: 50px;
				text-align: right;
				a {
					display: inline;
					padding: 0;
					border-bottom: 1px solid #37352f;
					font-size: 14px;
					font-variant: tabular-nums;
					border: none;
					color: #37352f;
				}
			}
			margin: auto;
			margin-top: 30px;
			width: 368px;
			height: 350px;
			box-sizing: border-box;
			padding: 50px 0;
			.yueduBOX {
				width: 100%;
				margin-bottom: 20px;
				display: flex;
				justify-content: space-evenly;
				flex-wrap: nowrap;

				div {
					text-align: center;
					flex-grow: 1;
					width: 40%;
					display: inline-block;
				}
			}
			.centerBox {
				display: flex;
				margin-bottom: 10px;
				justify-content: center;
			}
			.a_hre {
				text-align: center;
			}
			p {
				text-align: center;
				font-size: 18px;
				color: black;
			}
			.butonOption {
				width: 120px;
				height: 40px;
				position: relative;
				left: 250px;
				top: -40px;
			}
		}
		.changeTab {
			width: 100%;
			height: 35px;

			display: flex;
			justify-content: space-between;
			span {
				font-size: 18px;
				font-weight: bold;
				display: inline-block;
			}
		}
	}
	//外部样式
	#components-form-demo-normal-login .login-form {
		max-width: 300px;
	}
	#components-form-demo-normal-login .login-form-forgot {
		float: right;
	}
	#components-form-demo-normal-login .login-form-button {
		margin: auto;
		width: 140px;
		height: 45px;
	}
	.a_hre {
		text-align: center;
	}
}
</style>
